






































import { Component, Vue, Watch } from 'vue-property-decorator';
import { addWord, wordList, deleteWord } from '@/api/stop-words';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'StopWords',
})
export default class extends Vue {
  @Watch('sort')
  private handleChangeSort(sort: string) {
    /* eslint-disable */
    switch (sort) {
      case 'date':
        this.words.sort((a: any, b: any) => {
          return new Date(b.createDate).valueOf() - new Date(a.createDate).valueOf();
        });
        break;
      case 'word':
        this.words.sort((a: any, b: any) => {
          const x = a.word.toLowerCase();
          const y = b.word.toLowerCase();
          if (x < y) return -1;
          if (y > x) return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    /* eslint-enable */
  }

  private form = {
    word: '',
  };

  private formLoading = true;

  private rules = {
    word: [
      { required: true, message: '단어를 입력해주세요.', trigger: 'blur' },
      { validator: this.wordValidator, trigger: 'blur' },
    ],
  }

  private sort = 'word';

  private wordValidator(rules: any, value: string, callback: Function) {
    const wordIndex = this.words.findIndex((stopWord: any) => stopWord.word === value);
    if (wordIndex > -1) callback(new Error('중복된 단어입니다.'));
    callback();
  }

  private words = []

  private handleAdd(form: any) {
    (this.$refs.stopWordForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.formLoading = true;
        addWord(form).then(() => {
          this.form.word = '';
          this.handleList();
          this.formLoading = false;
        }).catch((error) => {
          this.$message.error(error.response.data);
        });
      }
    });
  }

  private handleList() {
    this.formLoading = true;
    wordList().then((res) => {
      this.words = res.data;
      this.handleChangeSort(this.sort);
      this.formLoading = false;
    });
  }

  private handleDelete(item: any) {
    this.$confirm(`정말 ${item.word}을(를) 삭제하시겠습니까?`, 'Warning', {
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deleteWord(item.uid).then(() => {
        this.handleList();
      });
    });
  }

  mounted() {
    this.handleList();
  }
}
