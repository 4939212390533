import request from '@/util/request';

const PATH = '/learning/stop-words';

export const addWord = (form: any) => request({
  url: `${PATH}`,
  method: 'post',
  data: form,
});

export const wordList = () => request({
  url: `${PATH}/list`,
  method: 'get',
});

export const deleteWord = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});
